<template>
  <section>
    <div v-if="showData">
      <b-row class="mb-5">
        <b-col>
          <h1>{{ $t("interactivities.create") }}</h1>
          <h5 class="text-primary">
            {{ $t("interactivities.select") }}
          </h5>
        </b-col>
        <b-col class="d-flex justify-content-end mr-1 m-2">
          <b-button
            variant="danger"
            @click="$router.go(-1)"
          >
            {{
              $t("dataGeneric.goBack")
            }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-card-header style="justify-content: center">
              <h3>{{ $t('chooseInt') }}</h3>
            </b-card-header>
            <b-card-body class="cardBody">
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('VID')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="VideoIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>{{ $t('videoInt') }}</h5>
                </b-col>
              </b-col>
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('FVID')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="VideoOffIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>{{ $t("interactivities.fVideo") }}</h5>
                </b-col>
              </b-col>
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('QUEST')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="FileTextIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>{{ $t("interactivities.quest") }}</h5>
                </b-col>
              </b-col>
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('INFO')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="InfoIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>{{ $t("interactivities.info") }}</h5>
                </b-col>
              </b-col>
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('DSHOP')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="ShoppingBagIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>{{ $t("interactivities.shop") }}</h5>
                </b-col>
              </b-col>
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('ADCART')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="ShoppingCartIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>{{ $t("interactivities.add") }}</h5>
                </b-col>
              </b-col>
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('DEEP')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="ExternalLinkIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>DEEPLINKING</h5>
                </b-col>
              </b-col>
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('PUSH')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="SendIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>PUSH</h5>
                </b-col>
              </b-col>
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('EMAIL')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="MailIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>E-MAIL</h5>
                </b-col>
              </b-col>
              <b-col
                md="10"
                class="elementos"
                @click="FormWizard('BANN')"
              >
                <b-col>
                  <feather-icon
                    color="white"
                    icon="MonitorIcon"
                    size="40"
                  />
                </b-col>
                <b-col>
                  <h5>BANNER</h5>
                </b-col>
              </b-col>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import { required } from '@validations'
import * as constants from '@core/utils/constants'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import { isUserInGroup, messageError } from '@/store/functions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
  },
  data() {
    return {
      required,
      type: 'str',
      headers: {},
      isSponsor: false,
      showData: false,
      userData: getUserData(),
    }
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {}

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    // Sponsor role. Uncomment it to restrict access again
    /* if (this.isSponsor) {
      this.checkContent()
    } else {
      this.showData = true
    } */
    this.showData = true
  },
  methods: {
    checkContent() {
      const { headers } = this

      axios
        .post('', {
          query: `
         query {
          allContents(id:"${this.$route.params.id}"){
            totalCount
            edges {
              node {
                creator {
                    id
                }
                id
                name
               
              }
            }          
          }          
        }
        `,
        }, { headers }).then(result => {
          messageError(result, this)
          const { creator } = result.data.data.allContents.edges[0].node
          let authorized = false
          if (this.isSponsor) {
            if (creator !== null) {
              if (creator.id === this.userData.id) { authorized = true }
            }
            if (!authorized) {
              this.$router.push({ name: 'misc-not-authorized' })
            }

            if (!authorized && this.isSponsor) { this.showData = false } else { this.showData = true }
          }
        }).catch(err => {
          // eslint-disable-next-line
          console.log(err)
        })
    },
    FormWizard(type) {
      const contentId = this.$route.params.id
      const resourceStart = this.$route.params.resource

      this.$router.push({
        name: 'interactivities-wizard',
        params: { id: contentId, resource: resourceStart, str: type },
      })
    },
    goToBackPage() {
      const contentId = this.$route.params.id

      this.$router.push({
        name: 'interactivities-video',
        params: { id: contentId },
      })
      // window.history.back();
    },
  },
}
</script>

<style scoped>
.cardBody {
  grid-template-columns: auto auto auto;
  display: grid;
  justify-content: space-evenly;
}

.cardBody .button {
  margin: 10px;
  width: 12vh;
  height: 12vh;
  font-size: 1.5vh;
}

.elementos {
  border-radius: 10%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  /*  height: 5vw;
  width: 9vw; */
  justify-content: space-evenly;
  height: 7vw;
  margin: 10px;
  flex-wrap: nowrap;
  object-fit: cover;
  white-space: nowrap;
  word-break: break-all;
}

.elementos:hover {
  background-color: #8888884a;
}
</style>
